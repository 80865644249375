import { createRouter, createWebHistory } from "vue-router";
import PerkSaratov6Feedback from "../views/PerkSaratov6Feedback.vue";
import PerkSaratov6ManagerBrief from "../views/PerkSaratov6ManagerBrief.vue";

import DjosSaratov25Feedback from "../views/DjosSaratov25Feedback.vue";

import HvanchPenza23Feedback from "../views/HvanchPenza23Feedback.vue";
import HvanchVolgograd28Feedback from "../views/HvanchVolgograd28Feedback.vue";

import UzbechkaSaratov7Feedback from "../views/UzbechkaSaratov7Feedback.vue";
import UzbechkaSaratov8Feedback from "../views/UzbechkaSaratov8Feedback.vue";
import UzbechkaPenza17Feedback from "../views/UzbechkaPenza17Feedback.vue";
import UzbechkaPenza26Feedback from "../views/UzbechkaPenza26Feedback.vue";

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/perk_saratov_6_feedback",
      name: "PerkSaratov6Feedback",
      component: PerkSaratov6Feedback,
      alias: "/perk_saratov_6_feedback",
      meta: { title: "PERK" },
    },
    {
      path: "/perk_saratov_6_manager_brief",
      name: "PerkSaratov6ManagerBrief",
      component: PerkSaratov6ManagerBrief,
      alias: "/perk_saratov_6_manager_brief",
      meta: { title: "PERK" },
    },
    {
      path: "/hvanch_penza_23_feedback",
      name: "HvanchPenza23Feedback",
      component: HvanchPenza23Feedback,
      alias: "/hvanch_penza_23_feedback",
      meta: { title: "HVANCH" },
    },
    {
      path: "/hvanch_volgograd_28_feedback",
      name: "HvanchVolgograd28Feedback",
      component: HvanchVolgograd28Feedback,
      alias: "/hvanch_volgograd_28_feedback",
      meta: { title: "HVANCH" },
    },
    {
      path: "/djos_saratov_25_feedback",
      name: "DjosSaratov25Feedback",
      component: DjosSaratov25Feedback,
      alias: "/djos_saratov_25_feedback",
      meta: { title: "HVANCH" },
    },
    {
      path: "/uzbechka_saratov_7_feedback",
      name: "UzbechkaSaratov7Feedback",
      component: UzbechkaSaratov7Feedback,
      alias: "/uzbechka_saratov_7_feedback",
      meta: { title: "Uzbechka" },
    },
    {
      path: "/uzbechka_saratov_8_feedback",
      name: "UzbechkaSaratov8Feedback",
      component: UzbechkaSaratov8Feedback,
      alias: "/uzbechka_saratov_8_feedback",
      meta: { title: "Uzbechka" },
    },
    {
      path: "/uzbechka_penza_17_feedback",
      name: "UzbechkaPenza17Feedback",
      component: UzbechkaPenza17Feedback,
      alias: "/uzbechka_penza_17_feedback",
      meta: { title: "Uzbechka" },
    },
    {
      path: "/uzbechka_penza_26_feedback",
      name: "UzbechkaPenza26Feedback",
      component: UzbechkaPenza26Feedback,
      alias: "/uzbechka_penza_26_feedback",
      meta: { title: "Uzbechka" },
    },
  ],
});
